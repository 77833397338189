import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Page from '../components/Page';

const StorePage = ({ data, location }) => {

  const { allSanityProduct } = data;

  return (
    <Wrapper seo={{ title: 'Store' }}  location={location}>
      <Page products={allSanityProduct.edges.map(edge => ({ ...edge.node }))} title="Store" className="store" />
    </Wrapper>
  );
}

export const StoreQuery = graphql`
  query storeQuery {
    allSanityProduct {
      edges {
        node {
          title
          id
          slug {
            current
          }
          mainImage {
            alt
            asset {
              fixed(width: 150) {
                ...GatsbySanityImageFixed
              }
            }
          }
          _rawDescription
          price
          buyButton {
            code
          }
          seoSettings {
            metaDescription
            titleTag
          }
        }
      }
    }
  }
`;

export default StorePage;
